import React, { Component } from 'react';
import { IBookmarkButtonProps, BookmarkIntent } from '../types.d';
import BookmarkIcon from '../icons/bookmark_icon';
import styles from './BookmarkButton.scss';
import  './CustomBookmarkButton.scss';
import legacyStyles from './BookmarkButton-legacy.scss';
import classNames from 'classnames';
import { isTablet } from 'react-device-detect';

class BookmarkButton extends Component<IBookmarkButtonProps> {
    private elementRef: React.RefObject<HTMLAnchorElement>;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.elementRef = React.createRef<HTMLAnchorElement>();
    }

    private handleClick(event) {
        if(event){
            event.preventDefault();
        }

        const payload: BookmarkIntent = {
            origin: this.props
        };
        window.shell.publishTo('ESCID.ESPP.Bookmark.ButtonWasClicked', payload);

        return false;
    }

    render() {
        const make_sure_this_is_referenced = legacyStyles.legacy_ordermodal_bookmark_add_button_outer;
        const showIcon = this.props.isRedesignOrderModal && (!this.props.isMobile && !isTablet);
        return showIcon ?
            <button
                className={styles.bookmark_add_button_icon}
                onClick={e => this.handleClick(e)}
                data-testid="bookmark-add-button"
            >
                <BookmarkIcon />
                <span>{this.props.localization.bookmark}</span>
            </button> :
            <a className={'pdp-invisible_container'} ref={this.elementRef}>
            <button aria-roledescription={make_sure_this_is_referenced} 
                className={classNames(styles.bookmark_add_button,
                    'bookmark-add-button pdp-compareShareBookmark_button_style'
                )} 
                onClick={e => this.handleClick(e)} data-testid="bookmark-add-button">
                <BookmarkIcon />
                <span>{this.props.localization.bookmark}</span>
            </button>
        </a>;
    }
}

export default BookmarkButton;
