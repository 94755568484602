import React from 'react';
/* eslint-disable max-len */
function BookmarkIcon() {
    return (
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 14.81641 20.80078">
            <path d="M13.916,20.80078a.89679.89679,0,0,1-.60743-.23633L7.4082,15.166,1.50781,20.56445A.90068.90068,0,0,1,0,19.90039V2.5332A2.53337,2.53337,0,0,1,2.52734,0h9.76172a2.53337,2.53337,0,0,1,2.52735,2.5332V19.90039a.89922.89922,0,0,1-.53809.82324A.88847.88847,0,0,1,13.916,20.80078ZM7.4082,13.0459a.89817.89817,0,0,1,.60742.23633l5,4.57519V2.5332a.7216.7216,0,0,0-.72656-.73242H2.52734a.72161.72161,0,0,0-.72656.73242V17.85742l5-4.57519A.89821.89821,0,0,1,7.4082,13.0459Z" />
        </svg>
    );
}

export default BookmarkIcon;
